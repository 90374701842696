.cv-btn {
  background-color: $mainBG;
  color: $CTATextColor;
  border: 0;
  border-radius: 50px;
  width: 10em;
  height: 2.7em;
  margin: 1.5em;
  font-weight: 700;
  box-shadow: inset -2px -2px $mainBGShadow;
  outline: none;
}

.cv-btn:active,
.cv-btn:focus {
  outline: none;
  outline-style: none;
}

.cv-btn:active {
  box-shadow: inset 2px 2px $mainBGShadow;
}
