@import "styles/colors";
@import "styles/fonts";

@import "styles/about";
@import "styles/buttons";
@import "styles/contact";
@import "styles/navbar";
@import "styles/landingPage";
@import "styles/teaching";
@import "styles/video";

* {
  z-index: 5;
}

body {
  outline: none;
  .App {
    a {
      color: inherit;
    }

    a:hover {
      text-decoration: none;
      color: inherit;
    }

    font-family: $mainFont;
    color: $mainTextColor;

    .title {
      margin: 2em;
      font-family: $titleFont;
      text-align: center;
    }
  }
}
