.contact-div {
  text-align: center;
  margin-top: 5em;
  font-size: 1em;

  .contact-bold {
    font-weight: 700;
    margin-right: 1em;
  }

  .contact-links {
    margin-top: 4em;
    font-size: 1.5em;
  }
}

@media screen and (min-width: 992px) {
  .contact-div {
    margin: 8em;
    font-size: 1.3em;

    .contact-bold {
      font-weight: 700;
      margin-right: 1em;
    }

    .contact-links {
      font-size: 1.7em;
    }
  }
}
