.navbar {
  font-family: $titleFont;

  .navbar-brand {
    font-size: 1.5em;
    font-family: $brandFont;
    font-weight: 600;
  }

  .navbar-custom-links {
    color: $navbarTextColor;
    font-size: 1.2em;
  }
  .navbar-custom-links:hover {
    text-decoration: none;
  }
}

@media screen and (min-width: 992px) {
  .navbar {
    padding: 0 50px !important;
    height: 100px;
    .navbar-brand {
      font-size: 2em;
      margin-right: 1.5em;
    }
    .navbar-custom-links {
      color: $navbarTextColor;
      font-size: 1.4em;
    }
  }
}
