.teaching-div {
  margin: 10% 10%;
}

@media screen and (min-width: 992px) {
  .teaching-div {
    margin: 10% 25%;
    font-size: 1.5em;
  }
}
