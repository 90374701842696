$navBarBG: #8daef3;
$mainBG: #f7980a;
$mainBGShadow: #d4840b;

$mainTextColor: rgb(66, 66, 66);

$CTATextColor: rgb(247, 247, 247);
$CTAHoverColor: rgb(216, 212, 205);

$navbarTextColor: rgb(56, 56, 56);
