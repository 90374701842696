.video-image {
  margin: 1.8em 0.1em;
  align-content: center;
}

.video-click {
  font-size: 0.8em;
}

.video-thumbnail {
  width: 100%;
  border-radius: 6px;
  // border: solid $mainTextColor 1px;
}

.video-description {
  font-size: 1em;
}

#video-name {
  font-style: italic;
  font-weight: 600;
}

@media screen and (min-width: 992px) {
  .video-description {
    font-size: 1.3em;
  }
}
