.about-container {
  #about-image {
    width: 100%;
  }

  .paragraph-inset {
    margin-left: 20px;
  }

  .about-links {
    font-weight: bold;
  }
}
