.background {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
  overflow: hidden;
}

.background-image {
  filter: blur(1px);
  background-image: url("../imgs/lucia1.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.landing-page-cta {
  color: $CTATextColor;
  font-family: $brandFont;
  text-align: center;
  margin-top: 9em;
  font-size: 2.1em;
}

@media screen and (min-width: 992px) {
  .landing-page-cta {
    font-size: 2.5em;
    margin-top: 15em;
  }
}

#landing-page-link:hover {
  color: $CTAHoverColor;
  transition: 300ms;
}
